<template>
  <app-layout>
    <router-view/>
  </app-layout>
</template>

<script>
import AppLayout from "../../layout/AppLayout";

export default {
  components: {
    "app-layout": AppLayout,

  }
};
</script>
