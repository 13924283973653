<template>
  <div id="app">
    <Header/>
    <slot></slot>
    <Footer/>
  </div>
</template>


<script>
import Header from '../components/navigation/Header'
import Footer from '../components/navigation/Footer'


export default {
  components: {
    Header,
    Footer,
  }
}
</script>
