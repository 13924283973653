<template>

  <header id="header">
    <div class="container position-relative">
      <nav class="d-flex">

        <div class="logo">
          <router-link to="/users">
            <img src="../../assets/img/logo.png" alt="Logo Image">
          </router-link>
        </div>

        <div class="hamburger">
          <div class="line1"></div>
          <div class="line2"></div>
          <div class="line3"></div>
        </div>

        <ul class="nav-links">
          <li>
            <router-link to="/user/">Add User</router-link>
          </li>
          <li>
            <a href="#" @click="onLogout">Logout</a>
          </li>
          <!-- <li>
            <router-link to="/user-data/user">User</router-link>
          </li>
          <li>
            <router-link to="/login/login">Log out</router-link>
          </li>-->
        </ul>
      </nav>

    </div>
  </header>
</template>


<style scoped>
a, span, i, p {
  display: inline-block;
  text-decoration: none;
}

/****header Section start ******/
header {
  top: 0;
  position: fixed;
  background: white;
  width: 100%;
  z-index: 9999;
  padding: 10px 0px;
}

header.sticky {
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px #55555529;
  box-shadow: 0px 0px 10px #55555529;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

header.sticky .login-button {
  color: #000;
}

nav {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px 0px 20px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  z-index: 10;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 80px;
}

/*Styling logo*/
.logo img {
  /* margin-top: -25px;
  margin-right: 70px; */
  margin: auto;
  width: 160px;
}

/*Styling Links*/
.nav-links {
  height: 50px;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  text-transform: capitalize;
}

.nav-links li .m-icon {
  color: #1a78a8;
  margin-right: 15px;
  display: none;
}

.nav-links li:hover .dropdown {
  opacity: 1;
  visibility: visible;
}

.nav-links li .dropdown {
  border-radius: 5px;
  width: 270px;
  padding: 30px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px 1px #00000052;
  box-shadow: 0px 0px 10px 1px #00000052;
  position: absolute;
  top: 130%;
  left: 30px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: .3s;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
}

.nav-links li .dropdown li {
  margin-bottom: 10px;
}

.nav-links li .dropdown li i {
  margin-right: 15px;
  color: #1E8BC2;
  font-size: 18px;
}

.nav-links li .dropdown li a:hover {
  color: #1E8BC2;
}

.nav-links li .dropdown::before {
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -35px;
  left: 50%;
  content: '';
  width: 50px;
  height: 50px;
  background: #ffffff;
  z-index: 999;
  -webkit-clip-path: polygon(50% 31%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 31%, 0% 100%, 100% 100%);
  -webkit-box-shadow: 0px 0px 10px 1px #00000052;
  box-shadow: 0px 0px 10px 1px #00000052;
}

.nav-links li {
  position: relative;
}

.nav-links li a {
  font-weight: 700;
  text-decoration: none;
  margin: 20px 25px 0px 0px;
  color: #171e25;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 15px;
}

.nav-links li a:hover {
  color: #FE930E;
}

/*Styling Hamburger Icon*/
.hamburger div {
  width: 30px;
  height: 3px;
  background: #000000;
  margin: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.hamburger {
  display: none;
}

/*responsive style */

@media screen and (min-device-width: 992px) and (max-device-width: 1200px) {
  .nav-links li a {
    margin: 20px 30px 0px 0px;
  }

}


@media screen and (max-width: 992px) {
  header {
    background: #ffffff;
    box-shadow: 0px 0px 20px #00000029;
    height: 85px;
  }

  .nav-links {
    position: absolute;
    height: auto;
    width: 100%;
  }

  .nav-links li .m-icon {
    display: inline-block;
    width: 10px;
  }

  nav {
    position: absolute;
    z-index: 3;
  }

  .hamburger {
    display: block;
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 50%;
    -webkit-transform: translate(-5%, -50%);
    transform: translate(-5%, -50%);
    z-index: 2;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
  }

  .nav-links {
    top: 75px;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    position: absolute;
    background: #fff;
    padding: 30px;
    width: 95%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    clip-path: circle(50px at 90% -20%);
    -webkit-clip-path: circle(0px at 90% -10%);
    -webkit-transition: all 1s ease-out;
    transition: all .3s ease-out;
    pointer-events: none;
  }

  .nav-links.open {
    clip-path: circle(1000px at 80% -10%);
    -webkit-clip-path: circle(1000px at 80% -10%);
    pointer-events: all;
    width: 95%;
    box-shadow: 0px 40px 25px 1px #c0c0c0e0;
    border-radius: 5px;
  }

  .nav-links li {
    opacity: 0;
  }

  .nav-links li:nth-child(1) {
    -webkit-transition: all 0.3s ease 0.1s;
    transition: all 0.3s ease 0.1s;
  }

  .nav-links li:nth-child(2) {
    -webkit-transition: all 0.3s ease 0.2s;
    transition: all 0.3s ease 0.2s;
  }

  .nav-links li:nth-child(3) {
    -webkit-transition: all 0.3s ease 0.2s;
    transition: all 0.3s ease 0.2s;
  }

  .nav-links li:nth-child(4) {
    -webkit-transition: all 0.3s ease 0.4s;
    transition: all 0.3s ease 0.4s;
  }

  .nav-links li:nth-child(5) {
    -webkit-transition: all 0.3s ease 0.5s;
    transition: all 0.3s ease 0.5s;
  }

  .nav-links li:nth-child(6) {
    -webkit-transition: all 0.3s ease 0.5s;
    transition: all 0.3s ease 0.5s;
  }

  .nav-links li:nth-child(6) {
    -webkit-transition: all 0.3s ease 0.5s;
    transition: all 0.3s ease 0.5s;
  }

  li.fade {
    opacity: 1;
  }
}

/*Animating Hamburger Icon on Click*/
.toggle .line1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
  width: 0;
}

.toggle .line3 {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
  transform: rotate(45deg) translate(-5px, -6px);
}


@media screen and (max-width: 768px) {
  header.sticky {
    box-shadow: none;
  }

  header {
    box-shadow: none;
  }

}


@media screen and (max-width: 480px) {

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .nav-links {
    width: 87%;
  }

  .nav-links.open {
    width: 87%;
  }

  .hamburger {
    right: 45px;
    top: 35%;
  }
}


</style>


<script>

import {mapActions} from "vuex";

window.$ = window.jQuery = require('jquery');

export default {
  methods: {
    ...mapActions({
      logOut: 'auth/logOut',
    }),
    onLogout() {
      this.logOut().then(
          this.$router.replace({
            name: 'login'
          })
      )
    }
  },
  mounted: function () {
    !(function ($) {
      "use strict";

      // Mobile Navigation
      const hamburger = document.querySelector(".hamburger");
      const navLinks = document.querySelector(".nav-links");
      const links = document.querySelectorAll(".nav-links li");

      hamburger.addEventListener('click', () => {
        //Animate Links
        navLinks.classList.toggle("open");
        links.forEach(link => {
          link.classList.toggle("fade");
        });

        //Hamburger Animation
        hamburger.classList.toggle("toggle");
      });

      $(window).scroll(function () {
        var height = $(window).scrollTop();
        if (height > 100) {
          $('header').addClass('sticky');
        } else {
          $('header').removeClass('sticky');
        }
      });
      const $header = document.querySelectorAll('header')[0];
      let lastKnownScrollTop = 0;
      let headerTop = 0;
      let headerHeight = $header.offsetHeight;

      window.addEventListener('scroll', function (event) {
        let scrollTop = event.target.scrollingElement.scrollTop;
        let offset = scrollTop - lastKnownScrollTop;
        headerTop -= offset;
        headerTop = headerTop > 0 ? 0 : headerTop;
        headerTop = headerTop < -1 * headerHeight ? -1 * headerHeight : headerTop;
        $header.style.transform = `translateY(${headerTop}px)`;
        lastKnownScrollTop = scrollTop;
      })

    })(window.jQuery);
  },
}
</script>
