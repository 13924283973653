<template>
  <!-- ======= Footer ======= -->

  <footer id="footer">
    <div class="footer-pc">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-4 col-xl-4">
            <div class="footer-logo w-50">
              <img src="../../assets/img/logo.png" alt="">
            </div>

          </div>
          <div class="col-md-4 col-lg-4 col-xl-4" style="text-align: center">
            <div class="copyright-text">
              <span>&copy; <strong>Bill generator</strong>.
                <br/>All rights reserved {{ new Date().getFullYear() }}.</span>
              <br/>
              <span>
                <a rel="noreferrer" class="copyright-text"
                   href="https://madewithlove.org.in/startup/bill-generator" target="_blank"
                   style="color:#000000">Made with <span
                    style="font-size:18px; color: #e74c3c">&hearts;</span> in India
                </a>
              </span>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xl-4" style="text-align: right">
            <ul>
              <h4>Contact Us</h4>
              <li>Koramangala,</li>
              <li>Bangalore, 560034 India</li>
              <li>Mail: <a href="mailto:portal@billgenerator.in"> portal@billgenerator.in</a></li>
              <!-- <li>Phone: <p> +918830286267</p></li>-->
              <li class="social-link">
                <a href="https://www.facebook.com/Bill-Generator-105765535302625" target="_blank"><i
                    class='bx bxl-facebook footerIcons'></i></a>
                <a href="#" target="_blank"><i class="bx bxl-twitter footerIcons"></i></a>
                <a href="#" target="_blank"><i class="bx bxl-linkedin footerIcons"></i></a>
                <a href="#" target="_blank"><i class="bx bxl-instagram footerIcons"></i></a>
                <a href="#" target="_blank"><i class="bx bxl-youtube footerIcons"></i></a>
                <a href="#" target="_blank"><i class="bx bxl-pinterest-alt footerIcons"></i></a>
                <a href="#" target="_blank"><i class="bx bxl-tumblr footerIcons"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>


    <!-- Mobile or small device footer ---- -->
    <div class="mobile-footer">
      <div class="container">
        <div class="footer-logo">
          <img src="../../assets/img/logo.png" alt="">
        </div>
        <section id="content-faq">
          <div id="accordion" class="accordion-container">


            <article class="content-entry mb-5">
              <h4 class="article-title"><i class='bx bxs-down-arrow'></i>
                Contact Us
              </h4>
              <div class="accordion-content">
                <ul>
                  <li>Koramangala,</li>
                  <li>Bangalore, 560034 India</li>
                  <li>Mail: <a href="mailto:portal@billgenerator.in"> portal@billgenerator.in</a></li>
                  <!-- <li>Phone: <p> +918830286267</p></li>-->
                </ul>
              </div>
              <!--/.accordion-content-->
            </article>
          </div>
        </section>
        <!-- mobile footer social-link -->
        <ul>
          <li class="social-link">
            <a href="https://www.facebook.com/Bill-Generator-105765535302625" target="_blank"><i
                class='bx bxl-facebook footerIcons'></i></a>
            <a href="#" target="_blank"><i class="bx bxl-twitter footerIcons"></i></a>
            <a href="#" target="_blank"><i class="bx bxl-linkedin footerIcons"></i></a>
            <a href="#" target="_blank"><i class="bx bxl-instagram footerIcons"></i></a>
            <a href="#" target="_blank"><i class="bx bxl-youtube footerIcons"></i></a>
            <a href="#" target="_blank"><i class="bx bxl-pinterest-alt footerIcons"></i></a>
            <a href="#" target="_blank"><i class="bx bxl-tumblr footerIcons"></i></a>
          </li>
        </ul>
        <!-- mobile footer copyright text -- -->
        <div class="copyright-text">
            <span>&copy; <strong>Bill generator</strong> .
              All rights reserved {{ new Date().getFullYear() }}.</span>
          <span>
                        <a rel="noreferrer" class="copyright-text"
                           href="https://madewithlove.org.in/startup/bill-generator" target="_blank"
                           style="color:#000000">Made with <span
                            style="font-size:18px; color: #e74c3c">&hearts;</span> in India
                        </a>
                </span>
        </div>
      </div>
    </div>


  </footer>

  <!-- End Footer -->


</template>

<style scoped>
a, span, i, p {
  display: inline-block;
  text-decoration: none;
}

.footerIcons {
  font-size: 18px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
/**** Footer section*****/
footer {
  padding: 50px 0px;
  margin-top: 50px;
}

footer .footer-pc .footer-logo img {
  width: 90%;
  margin-bottom: 30px;
}

footer .footer-pc h4 {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}

footer li {
  color: #606e7b;
  font-size: 14px;
  line-height: 1.72;
}

footer a {
  color: #606e7b;
  font-size: 14px;
}

footer a:hover {
  color: #000;
  text-decoration: none;
}

footer .social-link {
  margin-top: 10px;
}

footer .social-link a {
  margin-right: 10px;
  color: #353535;
}

footer .social-link a:hover {
  color: #000;
}

footer .mobile-footer {
  display: none;
}

footer .mobile-footer .footer-logo img {
  width: 200px;
  margin-bottom: 30px;
}

footer .mobile-footer #content-faq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

footer .mobile-footer .content-entry {
  margin-bottom: 30px;
}

footer .mobile-footer .accordion-container {
  position: relative;
  width: 100%;
  border-top: none;
  outline: 0;
  cursor: pointer;
}

footer .mobile-footer .accordion-container .article-title {
  display: block;
  position: relative;
  padding-bottom: 10px;
  letter-spacing: .07em;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  cursor: pointer;
}


footer .mobile-footer .accordion-container .content-entry i {
  position: absolute;
  right: 0px;
  font-style: normal;
  font-size: 14px;
  color: #000;
}

/*
footer .mobile-footer .accordion-container .content-entry i:before {
  content: "\f0d7";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
}

footer .mobile-footer .accordion-container .content-entry.open i:before {
  content: "\f0de";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
}*/

footer .mobile-footer .accordion-content {
  display: none;
  margin-bottom: 30px;
}

footer .mobile-footer .accordion-content li {
  padding-bottom: 10px;
}

footer .mobile-footer #content {
  width: 100%;
}

footer .mobile-footer #description h2 {
  text-align: center;
}

@media all and (min-width: 860px) {
  footer .mobile-footer #content {
    width: 70%;
    margin: 0 auto;
  }
}

footer .mobile-footer .social-link {
  margin: 20px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

footer .mobile-footer .social-link a {
  font-size: 25px;
}

footer .mobile-footer .copyright-text {
  text-align: center;
}

/*Stying for small screens*/


/*Stying for small screens*/


@media screen and (min-device-width: 992px) and (max-device-width: 1200px) {
  .nav-links li a {
    margin: 20px 30px 0px 0px;
  }
}

@media screen and (max-width: 992px) {

  nav {
    position: absolute;
    z-index: 3;
  }

  footer .footer-pc {
    display: none;
  }

  footer .mobile-footer {
    display: unset;
  }

}


@media screen and (max-width: 768px) {

  section.banner .banner-left h1 {
    text-align: start;
    font-size: 36px;
    margin-top: 50px;
  }

  section.banner .banner-left p {
    font-size: 20px;
  }

  section.banner .banner-left .brand-logo img {
    margin: 0px 10px;
  }


  .section-title h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  section.tab nav {
    display: none;
  }

  section.tab .single-tab p {
    color: #606e7b;
    line-height: 1.4;
    font-size: 17px;
    font-weight: 400;
  }
}


@media screen and (max-width: 480px) {

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  footer .mobile-footer .social-link {
    margin: 5px 20px;
  }

  footer {
    padding: 50px 0px 1px 0px;
  }

  footer .mobile-footer .social-link a {
    font-size: 20px;
  }

  footer .mobile-footer .copyright-text {
    padding: 10px 0px;
  }

}

</style>


<script>

window.$ = window.jQuery = require('jquery');

export default {

  mounted: function () {
    !(function ($) {
      var Accordion = function (el, multiple) {
        this.el = el || {};
        this.multiple = multiple || false;

        var links = this.el.find('.article-title');
        links.on('click', {
          el: this.el,
          multiple: this.multiple
        }, this.dropdown)
      }
      Accordion.prototype.dropdown = function (e) {
        var $el = e.data.el;
        var $this = $(this),
            $next = $this.next();

        $next.slideToggle();
        $this.parent().toggleClass('open');

        if (!e.data.multiple) {
          $el.find('.accordion-content').not($next).slideUp().parent().removeClass('open');
        }
      }
      /*this.accordion = */
      new Accordion($('.accordion-container'), false);
    })(window.jQuery);

  },
}
</script>



